<template>
  <v-card width="40%" style="margin: 15px auto;">
    <v-card-subtitle class="primary white--text text-center">
      Запуск тех. процесса
    </v-card-subtitle>
    <v-card-text>
      <div class="d-flex flex-column pt-3">
          <v-autocomplete
            label="Поиск шаблона..."
            v-model="selectedProc"
            :items="procs"
            item-text="name"
            :search-input.sync="searchProc"
            clearable
            autofocus
            hide-no-data
            return-object
          />
          <v-text-field
            label="Последняя серия"
            :value="selectedProc.lastSerial ? selectedProc.lastSerial : 'Нет'"
            disabled
          ></v-text-field>
          <v-text-field
            label="Новая серия"
            v-model="procSerial"
          ></v-text-field>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="info" @click="goHome">Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :disabled="selectedProc||sending ? false : true"
        :loading="sending"
        @click="select"
        >
        Далее
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: ['proc'],
    data: () => ({
      procs: [],
      searchProc: null,
      searchRequestTimeout: null,
      selectedProc: { lastSerial: '' },
      loadingProcs: false,
      sending: false,
      procSerial: null,
    }),
    computed: {
      ...mapState('organization', {
        orgId: state => state.profile.id,
      }),
    },
    methods: {
      queryProcs(value) {
        this.$axios.get('/manufacture/process-template/index-active', {
          params: { name: value }
        }).then(r => {
          this.procs = r.data.items.slice()
        })
      },
      select: function() {
        this.sending = true
        this.$axios.post('manufacture/process/create', {
          orgId: this.orgId,
          templateId: this.selectedProc.id,
          serial: this.procSerial
        }).then(r => {
          this.$router.push('/manufacture/process/index-wait-appoint')
        }).catch(() => {}).finally(() => { this.sending = false })
      },
      goHome() {
        this.$router.push('/manufacture')
      }
    },
    watch: {
      searchProc(value) {
        if(value && value.length >= 2) {
			if (this.selectedProc && this.selectedProc.Name === value) {
			return
			}
			clearTimeout(this.searchRequestTimeout);
			this.searchRequestTimeout = setTimeout(() => this.queryProcs(value), 1000);
        }
      },
    },
  }
</script>
